import { request } from './axios'
import { TRange } from '../type'

export const Range = {
  get: async (page_number = 1): Promise<TRange[]> => {
    const {
      data: { ranges },
    } = await request({
      url: `/config/ranges/?page_number=${page_number}`,
    })
    return ranges
  },

  add: (ranges: TRange[]) =>
    request({
      method: 'post',
      url: '/config/ranges/',
      data: {
        ranges,
      },
    }),

  update: (ranges: TRange[]) =>
    request({
      method: 'put',
      url: '/config/ranges/',
      data: {
        ranges,
      },
    }),

  delete: async (name: string): Promise<number> => {
    const { status } = await request({
      method: 'delete',
      url: `/config/ranges/?range_name=${name}`,
    })
    return status
  },

  fetch: async (): Promise<TRange[]> => {
    const {
      data: { new_items },
    } = await request({
      url: '/configurations/fetch/missing/?type=Ranges',
    })
    return new_items
  },
}
