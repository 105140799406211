import { lazy } from 'react'

export const View = {
  Home: lazy(() => import(/*webpackChunkName:'home'*/ './home').then(({ Home }) => ({ default: Home }))),
  Upload: {
    Wrap: lazy(() => import(/*webpackChunkName:'upload'*/'./upload').then(({ Wrap }) => ({ default: Wrap }))),
    Upload: lazy(() => import(/*webpackChunkName:'upload'*/'./upload').then(({ Upload }) => ({ default: Upload }))),
    History: lazy(() => import(/*webpackChunkName:'upload'*/'./upload').then(({ History }) => ({ default: History })))
  },
  FeeCalculation: {
    FieldConfiguration: lazy(() => import(/*webpackChunkName:'fee-calculation'*/'./fee-calculation').then(({ FieldConfiguration }) => ({ default: FieldConfiguration }))),
    Flags: lazy(() => import(/*webpackChunkName:'fee-calculation'*/'./fee-calculation').then(({ Flags }) => ({ default: Flags }))),
    Rates: lazy(() => import(/*webpackChunkName:'fee-calculation'*/'./fee-calculation').then(({ Rates }) => ({ default: Rates }))),
    Ranges: lazy(() => import(/*webpackChunkName:'fee-calculation'*/'./fee-calculation').then(({ Ranges }) => ({ default: Ranges }))),
    FeeManagement: lazy(() => import(/*webpackChunkName:'fee-calculation'*/'./fee-calculation').then(({ FeeManagement }) => ({ default: FeeManagement }))),
    CoveredEntities: lazy(() => import(/*webpackChunkName:'fee-calculation'*/'./fee-calculation').then(({ CoveredEntities }) => ({ default: CoveredEntities }))),
    Drafts: lazy(() => import(/*webpackChunkName:'fee-calculation'*/'./fee-calculation').then(({ Drafts }) => ({ default: Drafts }))),
    Mappings: lazy(() => import(/*webpackChunkName:'fee-calculation'*/'./fee-calculation').then(({ Mappings }) => ({ default: Mappings })))
  },
  Support: lazy(() => import(/*webpackChunkName:'support'*/'./support').then(({ Support }) => ({ default: Support }))),
  Admin: {
    CategoryClassifications: lazy(() => import(/*webpackChunkName:'date-input'*/'./admin/category-classification').then(({ CategoryClassifications }) => ({ default: CategoryClassifications }))),
    CategoryClassification: lazy(() => import(/*webpackChunkName:'date-input'*/'./admin/category-classification').then(({ CategoryClassification }) => ({ default: CategoryClassification }))),
    Synchronization: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { Synchronization } }) => ({ default: Synchronization }))),
    UserRolesManagement: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { UserRolesManagement } }) => ({ default: UserRolesManagement }))),
    Permissions: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { Permissions } }) => ({ default: Permissions }))),
    Resources: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { Resources } }) => ({ default: Resources }))),
    AuxRulesManagement: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { AuxRulesManagement } }) => ({ default: AuxRulesManagement }))),
    ValidationRules: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { ValidationRules } }) => ({ default: ValidationRules }))),
    UploadTemplate: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { UploadTemplate } }) => ({ default: UploadTemplate }))),
    ADManagement: {
      Users: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { ADManagement: { UsersTabs } } }) => ({ default: UsersTabs }))),
      ExternalUser: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { ADManagement: { ExternalUser } } }) => ({ default: ExternalUser }))),
      InternalUser: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { ADManagement: { InternalUser } } }) => ({ default: InternalUser }))),
      CoveredEntities: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { ADManagement: { CoveredEntities } } }) => ({ default: CoveredEntities }))),
      CoveredEntity: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { ADManagement: { CoveredEntity } } }) => ({ default: CoveredEntity }))),
      Memberships: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { ADManagement: { MembershipsTabs } } }) => ({ default: MembershipsTabs }))),
      Membership: lazy(() => import(/*webpackChunkName:'admin'*/'./admin').then(({ admin: { ADManagement: { Membership } } }) => ({ default: Membership })))
    }
  },
  Pharmasee: lazy(() => import(/*webpackChunkName:'pharmasee'*/'./pharmasee').then(({ Pharmasee }) => ({ default: Pharmasee }))),
  Sandbox: lazy(() => import(/*webpackChunkName:'sandbox'*/'./sandbox').then(({ Sandbox }) => ({ default: Sandbox })))
}
