import React, { createRef, useCallback, useEffect, useState, useRef } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Stack, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import 'jsoneditor/dist/jsoneditor.min.css'
import './JSONViewer.scss'
import { ValidationError } from 'jsoneditor'

interface IProps {
  isShow: boolean
  isLoading: boolean
  isDisabled: boolean
  title: string
  json: object
  handleSave: (json: object) => void
  handleClose: () => void
}

export const JSONViewer = ({ isShow, isLoading, isDisabled, title, json = {}, handleSave, handleClose }: IProps) => {
  const viewerRef = createRef<HTMLDivElement>()
  const editorRef = useRef<any>(null)
  const saveButtonRef = useRef<HTMLButtonElement>(null)
  const [JSONEditor, setJSONEditor] = useState<any>(null)
  const isErrorRef = useRef(false)

  const onValidationError = useCallback((errors: ValidationError[]) => {
    const hasErrors = errors.length > 0
    isErrorRef.current = hasErrors
    if (saveButtonRef.current) {
      if (hasErrors) {
        saveButtonRef.current.classList.add('Mui-disabled')
      } else {
        saveButtonRef.current.classList.remove('Mui-disabled')
      }
    }
  }, [])

  const expandAll = useCallback(() => {
    if (viewerRef.current && JSONEditor) {
      if (editorRef.current) {
        editorRef.current.destroy()
      }
      const options: any = {
        mode: isDisabled ? 'view' : 'code',
        onValidationError,
      }
      const editor = new JSONEditor(viewerRef.current, options)
      editor.set(json)

      if (options.mode === 'tree' || options.mode === 'view') {
        editor.expandAll()
      }

      editorRef.current = editor
    }
  }, [viewerRef, JSONEditor, isDisabled, json, onValidationError])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('jsoneditor').then((module) => {
        setJSONEditor(() => module.default)
      })
    }
  }, [])

  useEffect(() => {
    if (isShow && !isLoading && JSONEditor) {
      setTimeout(() => {
        expandAll()
      }, 0)
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.destroy()
      }
    }
  }, [isShow, isLoading, expandAll, JSONEditor])

  useEffect(() => {
    if (isDisabled && JSONEditor) {
      expandAll()
    }
  }, [expandAll, isDisabled, JSONEditor])

  const handleSaveClick = useCallback(() => {
    if (editorRef.current) {
      handleSave(editorRef.current.get())
    }
  }, [handleSave])

  return (
    <Dialog
      open={isShow}
      onClose={handleClose}
      fullWidth
      maxWidth='md'
      disableEscapeKeyDown
      aria-labelledby='dialog-title'
      data-test='json-viewer-dialog'
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: '90vh',
        },
      }}
    >
      <DialogTitle
        id='dialog-title'
        data-test='dialog-title'
        sx={{ m: 0, p: 2 }}
      >
        {title}
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ height: '90vh', maxHeight: '90vh' }}
      >
        {isLoading ? (
          <CircularProgress
            className='d-b m-l-auto m-r-auto'
            data-test='preloader'
          />
        ) : (
          <div
            ref={viewerRef}
            data-test='json-editor'
          />
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          spacing={2}
          direction='row'
          justifyContent={'space-between'}
          width={'100%'}
          paddingX={2}
          paddingY={1}
        >
          <Button
            onClick={handleClose}
            data-test='cancel-button'
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          {!isDisabled && (
            <Button
              variant='contained'
              ref={saveButtonRef}
              onClick={handleSaveClick}
              data-test='save-button'
            >
              Save
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
