import { request } from './axios'
import {
  TADConstant,
  TADCoveredEntities,
  TADCoveredEntity,
  TADGroups, TADInternalUserCreate, TADMembership,
  TADMembershipGroups,
  TADMemberships,
  TADUser,
  TADUserCreate,
  TADUsers,
  TADUserUpdate,
  TGroupDetail
} from '../type'
import { createDownloadLink } from '../utility/download-link'
import { dateFormat, Format } from '../utility/date-format'
import { TADInternalUser, TADInternalUserUpdate, TMeta, TMetaItemValue } from '../type/ad-management'

export const ADManagement = {

  constants: async (): Promise<TADConstant> => {
    const { data } = await request({
      url: '/jarvis_api/meta/'
    })

    const values = data['ORG_UNITS_NAMES'] ?? []

    return values.reduce((acc: TADConstant, item: TMetaItemValue) => {
      acc[item.label as keyof TADConstant] = item.value
      return acc
    }, {})
  },

  meta: async (): Promise<TMeta> => {
    const { data } = await request({
      url: '/jarvis_api/meta/'
    })

    return data
  },

  user: {

    create: async (data: TADUserCreate): Promise<void> => {
      await request({
        url: '/jarvis_api/users/',
        method: 'post',
        data
      })
    },

    createInternal: async (data: TADInternalUserCreate): Promise<void> => {
      await request({
        url: '/jarvis_api/users/internal/',
        method: 'post',
        data
      })
    },

    list: async (search = '', page_number = 1): Promise<TADUsers> => {
      const queryItems = []
      if (search) {
        queryItems.push(`UserLookupExtended=${ search }`)
      }
      if (!search) {
        queryItems.push(`page_number=${ page_number }`)
      }

      const { data: { total_count, total_pages, page_no, max_page_size, results } } = await request({
        url: `/jarvis_api/users/?${ queryItems.join('&') }`
      })

      return {
        total_count,
        total_pages,
        page_no,
        max_page_size,
        results
      }
    },
    
    internalList: async (search = '', page_number = 1, signal?: AbortSignal): Promise<TADUsers> => {
      const queryItems = [`page_number=${page_number}`]
      if (search) {
        queryItems.push(`UserLookupExtended=${ search }`)
      }

      const { data: { total_count, total_pages, page_no, max_page_size, results } } = await request({
        url: `/jarvis_api/users/in_folder/INT/?${ queryItems.join('&') }`,
        signal
      })

      return {
        total_count,
        total_pages,
        page_no,
        max_page_size,
        results
      }
    },

    externalList: async (search = '', page_number = 1, signal?: AbortSignal): Promise<TADUsers> => {
      const queryItems = [`page_number=${page_number}`]
      if (search) {
        queryItems.push(`UserLookupExtended=${ search }`)
      }

      const { data: { total_count, total_pages, page_no, max_page_size, results } } = await request({
        url: `/jarvis_api/users/in_folder/EXT/?${ queryItems.join('&') }`,
        signal
      })

      return {
        total_count,
        total_pages,
        page_no,
        max_page_size,
        results
      }
    },

    detail: async (user: string): Promise<TADUser | TADInternalUser> => {
      const { data } = await request({
        url: `/jarvis_api/users/detail/${ user }/`
      })

      return data
    },

    enable: async (name: string): Promise<void> => {
      await request({
        url: `/jarvis_api/users/enable/${ name }/`,
        method: 'patch'
      })
    },

    disable: async (name: string): Promise<void> => {
      await request({
        url: `/jarvis_api/users/disable/${ name }/`,
        method: 'patch'
      })
    },

    updateInternal: async (user: TADInternalUserUpdate): Promise<TADInternalUser> => {
      const { data } = await request({
        url: '/jarvis_api/users/internal/',
        method: 'patch',
        data: user
      })

      return data
    },

    update: async (user: TADUserUpdate): Promise<TADUser> => {
      const { data } = await request({
        url: '/jarvis_api/users/',
        method: 'patch',
        data: user
      })

      return data
    },

    download: async (isExternal: boolean, search?: string): Promise<void> => {
      const { data } = await request({
        responseType: 'arraybuffer',
        url: `/jarvis_api/users/${isExternal ? '' : 'internal/' }export_to_excel/${search ? `?UserLookupExtended=${search}` : ''}`,
        method: 'post'
      })
      createDownloadLink(data, `AD_${isExternal ? 'External' : 'Internal'}_Users_${dateFormat(new Date(), Format['YYYY-MM-DD__HH:MM:SS'])}.xlsx`)
    }

  },

  covered_entity: {

    allFolderItems: async (folders: string[]): Promise<TADMemberships> => {
      let total_count = 0
      let total_pages = 0
      let results: TADMembership[] = []

      const initialRequests = folders.map(folder =>
        request({ url: `/jarvis_api/groups/in_folder/${folder}/` }).then(response => ({
          folder,
          data: response.data
        }))
      )

      const initialResponses = await Promise.all(initialRequests)

      const allRequests = initialResponses.flatMap(({ folder, data }) => {
        total_count += data.total_count
        total_pages += data.total_pages

        const requests = []
        for (let i = 2; i <= data.total_pages; i++) {
          requests.push(request({ url: `/jarvis_api/groups/in_folder/${folder}/?page=${i}` }).then(response => ({
            folder,
            data: response.data
          })))
        }

        const initialResults = data.results.map((result: TADMembership) => ({
          ...result,
          FolderName: folder
        }))

        results = results.concat(initialResults)

        return requests
      })

      const allResponses = await Promise.all(allRequests)

      allResponses.forEach(({ folder, data }) => {
        const additionalResults = data.results.map((result: TADMembership) => ({
          ...result,
          FolderName: folder
        }))

        results = results.concat(additionalResults)
      })

      return {
        total_count,
        total_pages,
        page_no: 1,
        max_page_size: results.length,
        results
      }
    },

    list: async (search = '', page_number = 1): Promise<TADCoveredEntities> => {
      const s = search ? `&GroupLookupExtended=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/ce/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    detail: async (name: string): Promise<TADCoveredEntity> => {

      const { data } = await request({
        url: `/jarvis_api/groups/detail/pharmacy_info/${ name }/`
      })

      return data
    },

    create: async (name: string, description: string, memberships: string[]): Promise<void> => {

      await request({
        url: '/jarvis_api/groups/ce/',
        method: 'post',
        data: {
          Name: name,
          Description: description,
          Memberships: memberships,
        }
      })
    },

    update: async (name: string, description: string, newName: string, memberships: string[]): Promise<void> => {

      await request({
        url: '/jarvis_api/groups/ce/',
        method: 'patch',
        data: {
          Name: name,
          Description: description,
          NewName: newName,
          Memberships: memberships
        }
      })
    }
  },

  membership: {

    list: async (
      search = '',
      page_number = 1,
      type: '' | 'INT' | 'EXT' = '',
      signal?: AbortSignal
    ): Promise<TADMemberships> => {
      const s = search ? `&GroupLookupExtended=${ search }` : ''
      const t = type ? `&Type=${ type }` : ''
    
      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/memberships/extended/ce_info/?page_number=${ page_number }${ s }${ t }`,
        signal
      })
    
      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    listInternal: async (search = '', page_number = 1): Promise<TADMemberships> => {
      const s = search ? `&GroupLookup=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/memberships/internal/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    detail: async (name: string): Promise<TADMembershipGroups> => {
      const { data } = await request({
        url: `/jarvis_api/groups/memberships/member_of/${ name }/`
      })

      return data
    },

    create: async (name: string, description: string, memberships: string[], type: 'INT' | 'EXT'): Promise<void> => {
      await request({
        url: '/jarvis_api/groups/memberships/',
        method: 'post',
        data: {
          Name: name,
          Description: description,
          Memberships: memberships,
          Type: type
        }
      })
    },

    update: async (name: string, newName: string, description: string, memberships: string[], type: 'INT' | 'EXT'): Promise<void> => {
      await request({
        url: '/jarvis_api/groups/memberships/',
        method: 'patch',
        data: {
          Name: name,
          NewName: newName,
          Description: description,
          Memberships: memberships,
          Type: type
        }
      })
    }
  },

  group: {

    covered_entity: async (search = '', page_number = 1): Promise<TADGroups> => {
      const s = search ? `&GroupLookup=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/pharmacies/ce/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    qlik_app: async (search = '', page_number = 1): Promise<TADGroups> => {
      const s = search ? `&GroupLookup=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/qlik/apps/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    qlik_stream: async (search = '', page_number = 1): Promise<TADGroups> => {
      const s = search ? `&GroupLookup=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/qlik/streams/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    pharmacy: async (search = '', page_number = 1): Promise<TADGroups> => {
      const s = search ? `&GroupLookupExtended=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/pharmacies/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    omit: async (search = '', page_number = 1): Promise<TADGroups> => {
      const s = search ? `&GroupLookup=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/omissions/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    distributors: async (search = '', page_number = 1): Promise<TADGroups> => {
      const s = search ? `&GroupLookup=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/distributors/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    manufacturers: async (search = '', page_number = 1): Promise<TADGroups> => {
      const s = search ? `&GroupLookup=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/manufacturers/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    other: async (search = '', page_number = 1): Promise<TADGroups> => {
      const s = search ? `&GroupLookup=${ search }` : ''

      const { data: { results, max_page_size, page_no, total_count, total_pages } } = await request({
        url: `/jarvis_api/groups/others/?page_number=${ page_number }${ s }`
      })

      return {
        results,
        max_page_size,
        page_no,
        total_count,
        total_pages
      }
    },

    all_details: async (search = ''): Promise<TGroupDetail> => {
      const { data } = await request({
        url: `/jarvis_api/groups/details/all/top_level/${ search }/`
      })

      return data
    },
  }
}
