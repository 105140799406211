import { request } from './axios'
import { TFlag } from '../type'

export const Flag = {
  get: async (page_number = 1): Promise<TFlag[]> => {
    const {
      data: { flags },
    } = await request({
      url: `/config/flags/?page_number=${page_number}`,
    })
    return flags
  },

  add: (flags: TFlag[]) =>
    request({
      method: 'post',
      url: '/config/flags/',
      data: {
        flags,
      },
    }),

  update: (flags: TFlag[]) =>
    request({
      method: 'put',
      url: '/config/flags/',
      data: {
        flags,
      },
    }),

  delete: async (name: string): Promise<number> => {
    const { status } = await request({
      method: 'delete',
      url: `/config/flags/?flag_name=${name}`,
    })
    return status
  },

  fetch: async (): Promise<TFlag[]> => {
    const {
      data: { new_items },
    } = await request({
      url: '/configurations/fetch/missing/?type=Flags',
    })
    return new_items
  },
}
